import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import img from '../../src/images/New Wowizer Logo 1.svg'
import { Base_URL, Logout_User } from "../constants";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { TableChart } from "@mui/icons-material";
import SessionExpiredAlert from "./SessionExpiredAlert";
import Signout from "./Signout";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DescriptionIcon from '@mui/icons-material/Description';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const SocSideNav = () => {

  const [cookies, setCookie, removeCookie] = useCookies();
  
  const handleLogout = () => {
    var config = {
      method: 'get',
      url: Base_URL + Logout_User,
      headers: {}
  };

  axios(config)
      .then(function (response) {
        sessionStorage.clear();
        localStorage.clear();
        // Object.keys(cookies).forEach((cookieName) => {
        //   removeCookie(cookieName);
        // });
        window.location.href = '/';
      })
      .catch(function (error) {
        var config = {
        method: 'get',
        url: Base_URL + '/auth/refresh',
        headers: { }
        };

        axios(config)
        .then(function (response) {
          var config = {
            method: 'get',
            url: Base_URL + Logout_User,
            headers: {}
          };
          axios(config)
          .then(function (response) {
            localStorage.clear();
            // Object.keys(cookies).forEach((cookieName) => {
            //   removeCookie(cookieName);
            // });
            window.location.href = '/';
          })
        })
        .catch(function (error) {
        console.log(error);
        });
      });
  };

  const location = useLocation();

  const [subscriptionDetails, setSubscriptionDetails] = React.useState({});

  const [email, setEmail] = React.useState('');
  const [userRole, setUserRole] = React.useState('');
  const [displayEmail, setDisplayEmail] = React.useState('');

  React.useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + '/users/me',
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          const admin_email = response.data.data.super_user.email;
          const email = response.data.data.user.email;
          const subscription = response.data.data.subscription;
          localStorage.setItem('userRole', response.data.data.user.role);
          localStorage.setItem('archivalretention', response.data.data.subscription.archiveRetentionDays);
          localStorage.setItem('liveretention', response.data.data.subscription.liveRetentionDays);
          setSubscriptionDetails(subscription);
          setUserRole(response.data.data.user.role)
          setEmail(email);
          setDisplayEmail(admin_email === email ? email : `${admin_email}`)
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const [isHistoricalOpen, setIsHistoricalOpen] = React.useState(false);

  const toggleHistorical = () => {
    setIsHistoricalOpen(!isHistoricalOpen);
  };

  const [isRealtimeOpen, setIsRealtimeOpen] = React.useState(false);

  const toggleRealtime = () => {
    setIsRealtimeOpen(!isRealtimeOpen);
  };

  const [isAdminPanelOpen, setIsAdminPanelOpen] = React.useState(false);

  const toggleAdminPanel = () => {
    setIsAdminPanelOpen(!isAdminPanelOpen);
  };

  // const [logtype, setLogType] = React.useState('sample');

  // useEffect(() => {
  //   console.log("Current URL:", window.location.pathname);
  //   if (window.location.pathname === '/analyzelogs/realtimelogs/QV00/3') {
  //     console.log("Clearing sessionStorage...");
  //     sessionStorage.clear();
  //     // window.location.reload();
  //   }
  // }, [logtype]);  

  const Realtime = () => {
    localStorage.setItem("logtype", "realtime");
  };

  return (
    <>
    <SessionExpiredAlert />
    <div>
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/home" className="brand-link">
        <img src={img} alt="AdminLTE Logo" className="" style={{ opacity: '.8', width: '235px' }} />
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">  
              <li className={`first-step nav-item ${location.pathname === '/adminportal' ? 'active' : ''}`}>
              <Link to='/adminportal' className="nav-link">
                <AddAlertIcon />
                <p>
                  Alerts Dashboard
                </p>
              </Link>
              </li>
              <li className={`second-step nav-item ${location.pathname === '/selectcustomer' ? 'active' : ''}`}>
              <Link to='/selectcustomer' className="nav-link">
                <AccountCircleIcon />
                <p>
                  Choose Customer
                </p>
              </Link>
              </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
  </>
  )
}

export default SocSideNav