import React, { useState } from 'react'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Base_URL } from '../constants'
import axios from 'axios';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Box, Button, Checkbox, FormControl, FormLabel, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography,Card,CardContent, IconButton, RadioGroup, FormControlLabel, Radio, Tooltip, Divider } from '@mui/material'
import { Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import { FcFinePrint, FcInfo } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import RealTimeSideNav from '../components/RealTimeSideNav'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AlertDataBlock=(props)=>{
  return( <>
  <TableContainer><Table><TableBody>
  {
      Object.keys(props.alertData).map((x)=>
      {
        if(x=='facts'){
          return (<Card><CardContent>
            {
            props.alertData['facts'].map((dataVal)=>{
              dataVal.value=dataVal.value.trim();
              const regex_sel = /[^0-9.]/g;
              let tval=dataVal.value.replace(regex_sel, ``);   
              if(!isNaN(tval) && tval!="" && !isNaN(parseFloat(dataVal.value)) && isFinite(dataVal.value) && !Number.isInteger(parseFloat(dataVal.value)))
              {
                let formatedValue=parseFloat(dataVal.value).toFixed(2);
                dataVal.value=dataVal.value.replace(tval,formatedValue)
              }
                  return (
                   <div style={{display:'block'}}>
                    <div style={{display:'inline-block'}}> <b>{dataVal.name}</b>:</div>
                    <div style={{display:'inline-block', color: 'green'}}>  {dataVal.value}  </div> 
                    </div>
                  )
                })         
            }
          </CardContent></Card>);
        }
        else if(x=='msg')      
        {
          return (<Card><CardContent>MSG:{props.alertData[x]}</CardContent></Card>)
        }
      }
      )
  } 
  </TableBody></Table></TableContainer>
  </>
  )
}

const AlertInfoDataBlock=(props)=>{
  return( <>
  <TableContainer><Table><TableBody>
  {
      Object.keys(props.alertInfoData).map((x)=>
      {
          return (<Card><CardContent>
            {
              <div style={{display:'block', marginBottom: '-2%'}}>
              <div style={{display:'inline-block'}}> <b>{x}</b>:</div>
              <div style={{display:'inline-block'}}>  {props.alertInfoData[x]}  </div> 
              </div> 
            }
          </CardContent></Card>);
      }
      )
  } 
  </TableBody></Table></TableContainer>
  </>
  )
}

const Alerting = () => {
    const [data, setData] = React.useState({});
    const [monitordata, setMonitorData] = React.useState({});

    const [alertinfoopen, setAlertInfoOpen] = React.useState(false);
    
    const [viewalertinfodata, setViewAlertInfoData] = React.useState({});

    const [webhookstatus, setWebhookStatus] = React.useState();
    
    function handleAlertInfoOpen(alert_info){
      setViewAlertInfoData({
        'data': alert_info
      });

      setAlertInfoOpen(true);
    }

    const handleAlertInfoClose = () => setAlertInfoOpen(false);

    const columns = [
      { field: 'created_at', headerClassName: 'super-app-theme--header', headerName: 'Date-Time', flex: 0.7, cellClassName: (params) => {
        return 'word-wrap'; }},
      { field: 'hostname', headerClassName: 'super-app-theme--header', headerName: 'Host Name', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap'; } },
      { field: 'alert_name', headerClassName: 'super-app-theme--header', headerName: 'Alert Name', flex: 1, cellClassName: (params) => {
        return 'word-wrap';
      } },
      {
        field: 'severity',
        headerClassName: 'super-app-theme--header',
        headerName: 'Severity',
        flex: 0.4,
        cellClassName: 'word-wrap',
        renderCell: (params) => {
          const severityValue = params.value;
          let severityText = '';
    
          switch (severityValue) {
            case 1:
              severityText = 'High';
              break;
            case 2:
              severityText = 'High';
              break;
            case 3:
              severityText = 'Medium';
              break;
            case 4:
              severityText = 'Low';
              break;
            case 5:
              severityText = 'Info';
              break;
            default:
              severityText = 'Unknown';
          }
          return <span>{severityText}</span>;
        },
      },
      {
        field: 'alertstate',
        headerClassName: 'super-app-theme--header',
        headerName: 'Alert State',
        flex: 0.5,
        renderCell: (params) => {
          const alertState = params.value;
          let alertStateText = '';
          switch (alertState) {
            case 'open':
              alertStateText = 'Open';
              break;
            case 'acknowledged':
              alertStateText = 'Acknowledged';
              break;
            case 'close':
              alertStateText = 'Closed';
              break;
            default:
              alertStateText = 'Unknown';
          }
      
          return <span>{alertStateText}</span>;
        }
      },      
      {
        field: 'actions',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        flex: 0.7,
        renderCell: (params) => {
          const alertState = params.row.alertstate;
          const severity = params.row.severity;
      
          if (severity === 5) {
            return <Typography>Info</Typography>;
          }
      
          const stateHistory = params.row.alert_state_history
            ? params.row.alert_state_history.find(item => item.state === 'close')
            : null;
          const stateDetails = stateHistory
            ? `Updated At: ${new Date(stateHistory.updatedAt).toLocaleString()}\n` +
              `Updated By: ${stateHistory.updatedBy}\n` +
              `State Note: ${stateHistory.state_note}`
            : 'No notes';
      
          let actionButton;
          let infoIcon = null;
      
          switch (alertState) {
            case 'open':
              actionButton = (
                <Button
                  style={{ height: '22px' }}
                  onClick={() => handleAcknowledge(params.row.alert_id)}
                  variant="outlined"
                  color="primary"
                >
                  Acknowledge
                </Button>
              );
              break;
            case 'close':
              actionButton = <Typography>Closed</Typography>;
              break;
            case 'acknowledged':
              actionButton = (
                <Button
                  style={{ height: '22px' }}
                  onClick={() => handleClose(params.row.alert_id)}
                  variant="outlined"
                  color="error"
                >
                  Close
                </Button>
              );
              break;
            default:
              actionButton = null;
          }
      
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {actionButton}
              {infoIcon}
            </div>
          );
        },
      },      
      {
        field: 'data',
        headerClassName: 'super-app-theme--header',
        headerName: 'Details',
        flex: 0.5,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const stateHistory = params.row.alert_state_history
            ? params.row.alert_state_history.find(item => item.state === 'acknowledged')
            : null;
          const stateDetails = stateHistory
            ? `Acknowledged At: ${new Date(stateHistory.updatedAt).toLocaleString()}\n` +
              `Acknowledged By: ${stateHistory.updatedBy}\n` +
              `State Note: ${stateHistory.state_note}`
            : 'N/A';

            const stateCloseHistory = params.row.alert_state_history
            ? params.row.alert_state_history.find(item => item.state === 'close')
            : null;
          const stateCloseDetails = stateCloseHistory
            ? `Closed At: ${new Date(stateCloseHistory.updatedAt).toLocaleString()}\n` +
              `Closed By: ${stateCloseHistory.updatedBy}\n` +
              `State Note: ${stateCloseHistory.state_note}`
            : 'N/A';
      
          return (
            <div>
              <FcInfo
                size={30}
                style={{ cursor: 'pointer', marginLeft: '50%' }}
                onClick={() => handleOpenViewData(params.value, params.row.created_at, params.row.severity, stateDetails, stateCloseDetails)}
              />
            </div>
          );
        }
      }       
    ];

    const handleTeamsAlertClick = () => {
      swal({
        icon: 'info',
        title: 'WoWizer TPM Test Drive does not have the functionality!',
        text: "Kindly proceed with the complete sign-up to unlock all features.",
        buttons: {
          signUp: {
            text: "Complete Sign-up",
            value: "signUp",
            className: "black-text-button",
          },
          cancel: "Cancel",
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      })
        .then((value) => {
          if (value === "signUp") {
            const signUpPageUrl = "/register";
            window.open(signUpPageUrl, '_blank');
          }
        });      
    };

    const monitorcolumns = [    
      { field: 'monitor_name', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Name', flex: 0.9, cellClassName: (params) => {
        return 'word-wrap';
      } },  
      { field: 'monitor_severity', headerClassName: 'super-app-theme--header', headerName: 'Severity', flex: 0.2, cellClassName: (params) => {
        return 'word-wrap';
      } },  
      { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Status', flex: 0.4, cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => {
        const isMonitorEnabled = params.value;
        return isMonitorEnabled ? (
          <span style={{ color: 'green'}} >Enabled</span>
        ) : (
          <span style={{ color: 'red'}} >Disabled</span>
        );
      }, },
      {
        field: 'alert_info',
        headerClassName: 'super-app-theme--header',
        headerName: 'Info',
        flex: 0.3,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => (
          <><FcInfo onClick={() => handleAlertInfoOpen(params.value)} size={30} style={{ cursor: 'pointer' }}/>
          </>
        ),
      },
      {
        field: 'monitor_status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        flex: 0.4,
        sortable: false,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const isMonitorEnabled = params.value;
          const trialuser = localStorage.getItem('userRole')
          return trialuser === "trialuser" ? (
            <div>
              {isMonitorEnabled ? (
                <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Disable</Button>
              ) : (
                <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success">Enable</Button>
              )}
            </div>
          ) : (
            <div>
              {isMonitorEnabled ? (
                <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="error">Disable</Button>
              ) : (
                <Button size="small" onClick={() => handleStatusChange(params.row)} variant="contained" color="success">Enable</Button>
              )}
            </div>
          );
        },
      },
      {
        field: 'webhook_status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Webhook',
        flex: 0.4,
        sortable: false,
        cellClassName: (params) => {
          return 'word-wrap';
        },
        renderCell: (params) => {
          const isWebhookEnabled = params.value;
          const trialuser = localStorage.getItem('userRole')
          return trialuser === "trialuser" ? (
            <div>
              {isWebhookEnabled === true ? (
                <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Deactivate</Button>
              ) : isWebhookEnabled === false ? (
                <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success">Activate</Button>
              )
              :
                <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success" style={{ fontSize: '78%' }}>Not Configured</Button>
            }
            </div>
          ) : (
            <div>
              {isWebhookEnabled === true ? (
                <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="outlined" color="error">Deactivate</Button>
              ) : isWebhookEnabled === false ? (
                <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="contained" color="success">Activate</Button>
              ) : (
                <div>
                  <Typography variant="body2">
                    Not Configured
                  </Typography>
                </div>
              )}
            </div>
          );
        },
      }
    ];

    function handleStatusChange(row) {
      swal({
        title: "Are you sure to update the setting?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = row.monitor_status;
      
          status = !status;

          var data = JSON.stringify({
            "monitor_status": status,
            "monitor_id": row.monitor_id
          });
          
          var config = {
            method: 'post',
            url: Base_URL + '/alerts/monitor/status/',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function handleWebhookStatusChange(row) {
      swal({
        title: "Are you sure to update the webhook?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = row.webhook_status;
          
          status = !status;
          
          var data = JSON.stringify({
              "webhook_name": webhookname,
              "webhook_status": status,
              "monitor_id": row.monitor_id
          });
          
          var config = {
            method: 'patch',
            url: Base_URL + '/alerts/webhook/status',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function handleWebhookStatusChangeAll() {
      swal({
        title: "Are you sure to update the webhook?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let status = webhookstatus;
          status = !status;
          
          var data = JSON.stringify({
              "webhook_name": webhookname,
              "webhook_status": status,
          });
          
          var config = {
            method: 'patch',
            url: Base_URL + '/alerts/webhook/status',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });      
          swal("Setting Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    function UpdateAll(status) {
      swal({
        title: "Are you sure to update settings for all?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          var data = JSON.stringify({
            "monitor_status": status
          });
          
          var config = {
            method: 'post',
            url:  Base_URL + '/alerts/monitors/status/',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });
          swal("Settings Updated Successfully!", {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Settings not changed!");
        }
      });
    }

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);

    const handleChangePage = (newPage) => {
      setPage(newPage);
    };

    const handleChangePageSize = (newPageSize) => {
      setPageSize(newPageSize);
      setPage(0);
    };

    const totalRows = data.length;
    
    const [open, setOpen] = useState(false);
    const [openmonitor, setOpenMonitor] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    
    const handleSettingsClick = () => {
      setShowDropdown(!showDropdown);
    };

    const handleAlertsClick = () => {
      setOpen(true);
      var config = {
        method: 'get',
        url:  Base_URL + '/alerts/monitors/',
        headers: { }
      };
      
      axios(config)
      .then(function (response) {
        const MonitorData = response.data.data.map((element, index) => {
          return {
            "id" : index + 1,
            "monitor_id" : element.monitor_id,
            "monitor_name": element.monitor_name,
            "monitor_severity": element.monitor_severity,
            "monitor_status": element.monitor_status,
            "alert_info": element.wow_alert_info,
            "status": element.monitor_status,
            "webhook_status": element.webhook_status
          };
          });
          MonitorData.sort((a, b) => a.monitor_severity - b.monitor_severity);
          setMonitorData(MonitorData);
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    const [webhookValue, setWebhookValue] = useState('');

    const handleWebhookSubmit = () => {
      var data = JSON.stringify({
        "webhook_url": webhookValue
      });

      var config = {
        method: 'post',
        url: Base_URL + '/alerts/webhook/',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        swal("Webhook Added!", "", "success").then(function () {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleWebhookUpdate = () => {
      var data = JSON.stringify({
        "webhook_name": webhookname,
        "webhook_url": webhookValue
      });

      var config = {
        method: 'patch',
        url: Base_URL + '/alerts/webhook/',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        swal("Webhook URL Updated!", "", "success").then(function () {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleMonitorsClick = () => {
      setOpenMonitor(true);
    };

    const handleCloseModal = () => {
      setOpen(false);
    };

    const handleMonitorCloseModal = () => {
      setOpenMonitor(false);
    };
  
    const [modalpage, setModalPage] = useState(0);
    const handlePageChange = (params) => {
      setPage(params.page);
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 500,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const webhookstyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 300,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const viewdatastyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '7%',
      height: 500,
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      overflow:"auto",
      p: 4,
    };

    const SERVER_OPTIONS = {
      useCursorPagination: false,
    };

    const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 10,
    });
  
    const [rows, setRows] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const updateAlertState = (alertIds, alertState) => {
      setIsLoading(true);
      const data = JSON.stringify({
        "alerts_id": alertIds,
        "alert_state": alertState,
        "alert_state_note":"-"
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/alerts/up_state',
        headers: { 
          'Content-Type': 'application/json',
        },
        data: data
      };
      
      return axios.request(config)
        .then(response => {
          setIsLoading(false);
          fetchDat();
          fetchSummary();
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error:', error);
        });
    };
    
    const updateAlertCloseState = (alertId, alertState, note) => {
      setIsLoading(true);
      
      const data = JSON.stringify({
        "alerts_id": alertId,
        "alert_state": alertState,
        "alert_state_note": note
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/alerts/up_state',
        headers: { 
          'Content-Type': 'application/json',
        },
        data: data
      };
      
      return axios.request(config)
        .then(response => {
          setIsLoading(false);
          fetchDat();
          fetchSummary();
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error:', error);
        });
    };

    const handleAcknowledge = (alertId) => {
      updateAlertState(alertId.toString(), 'acknowledged')
        .then(() => {
          
        });
    };
    
    const handleOpen = (alertId) => {
      updateAlertState(alertId, 'open')
        .then(() => {
          
        });
    };

    const handleClose = (alertId) => {
      swal({
        title: '',
        text: 'Add closing notes taken to resolve this alert:',
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter your notes here...",
            type: "text",
          },
        },
        buttons: true,
        dangerMode: true,
      }).then((note) => {
        if (note) {
          updateAlertCloseState(alertId.toString(), 'close', note)
            .then(() => {
              swal('Success', 'Alert closed successfully!', 'success');
            })
            .catch((err) => {
              console.error('Error closing alert:', err);
              swal('Error', 'Failed to close the alert. Please try again.', 'error');
            });
        } else {
          swal('Cancelled', 'No notes added, action cancelled.', 'info');
        }
      });
    };

    const [webhookurlexists, setWebhookUrlExists] = React.useState();
    const [webhookname, setWebhookName] = React.useState();

    React.useEffect(() => {
      fetchWebhook();
    }, []);

    const fetchWebhook = () => {
      var config = {
        method: 'get',
        url: Base_URL + '/alerts/webhook',
        headers: { }
      };

      axios(config)
      .then(function (response) {
        setWebhookUrlExists(response.data.data.webhookconfig[0]['webhookUrl']);
        setWebhookName(response.data.data.webhookconfig[0]['webhookName']);
        setWebhookStatus(response.data.data.webhookconfig[0]['status']);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const [modalalertdata, setModalAlertData] = useState({});
    const [hostnames, setHostnames] = useState([]);
    const [selectedHostname, setSelectedHostname] = useState([]);
    const [alertnames, setAlertnames] = useState([]);
    const [selectedAlertname, setSelectedAlertname] = useState([]);
    const [selectedAlertstate, setSelectedState] = useState("");
    
    const [severity, setSeverity] = useState([1,2]);

    const [selectedSeverity, setSelectedSeverity] = useState('high');

    const handleCriticalSeverityClick = () => {
      setSeverity([1,2]);
      setSelectedSeverity("high");
      // setSelectedTimePeriod('all');
    };

    const handleHighSeverityClick = () => {
      setSeverity([3]);
      setSelectedSeverity("medium");
      // setSelectedTimePeriod('all');
    };

    const handleLowSeverityClick = () => {
      setSeverity([4]);
      setSelectedSeverity("low");
      // setSelectedTimePeriod('all');
    };

    const handleInfoSeverityClick = () => {
      setSeverity([5]);
      setSelectedSeverity("info");
      // setSelectedTimePeriod('all');
    };

    React.useEffect(() => {
      getFilter('');
    }, []);

    const getFilter = async => {
      var data = JSON.stringify({
        "from_ts": "",
        "to_ts": ""
      });
      
      var config = {
        method: 'post',
        url: Base_URL + '/alerts/get_filters',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        setHostnames(response.data.data.hostnames);
        setAlertnames(response.data.data.alertnames);
      })
      .catch(function (error) {
        console.log(error);
      });      
    }
  
    const handleHostnameChange = (event) => {
      setSelectedHostname(event.target.value==="All Hosts" ? [] : [event.target.value]);
    };
  
    const handleAlertnameChange = (event) => {
      setSelectedAlertname(event.target.value==="All Alerts" ? [] : [event.target.value]);
    };

    const handleAlertstateChange = (event) => {
      setSelectedState(event.target.value === "All States" ? "" : event.target.value);
    };    

    const [selectedTimePeriod, setSelectedTimePeriod] = useState('all');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // const handleTimePeriodChange = (event) => {
    //   const selectedValue = event.target.value;
    //   let startDate, endDate;
    //   if (selectedValue === 'last24') {
    //     endDate = new Date();
    //     startDate = new Date(endDate.getTime() - (24 * 60 * 60 * 1000));
    //   } else if (selectedValue === 'last7') {
    //     endDate = new Date();
    //     startDate = new Date(endDate.getTime() - (7 * 24 * 60 * 60 * 1000));
    //   } else {
    //     startDate = '';
    //     endDate = '';
    //   }
    
    //   setSelectedTimePeriod(selectedValue);
    //   fetchDat(startDate, endDate);
    //   fetchSummary(startDate, endDate)
    // };    
    const handleTimePeriodChange = (event) => {
      const selectedValue = event.target.value;
      let newStartDate, newEndDate;
      
      if (selectedValue === 'last24') {
        newEndDate = new Date();
        newStartDate = new Date(newEndDate.getTime() - (24 * 60 * 60 * 1000));
      } else if (selectedValue === 'last7') {
        newEndDate = new Date();
        newStartDate = new Date(newEndDate.getTime() - (7 * 24 * 60 * 60 * 1000));
      } else {
        newStartDate = '';
        newEndDate = '';
      }
    
      setSelectedTimePeriod(selectedValue);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    };    

    React.useEffect(() => {
      fetchDat(startDate, endDate);
    }, [paginationModel, severity, selectedAlertname, selectedAlertstate, selectedHostname, startDate, endDate]);

    const fetchDat = (startDate = '', endDate = '') => {
      setIsLoading(true);
    
      const requestData = {
        size: paginationModel.pageSize,
        page: paginationModel.page,
        hostname: selectedHostname,
        alertname: selectedAlertname,
        severity: severity,
        from_ts: startDate,
        to_ts: endDate
      };
    
      if (selectedAlertstate !== "") {
        requestData.alert_state = selectedAlertstate;
      }
    
      const jsonData = JSON.stringify(requestData);
    
      const config = {
        method: 'post',
        url: Base_URL + '/alerts',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: jsonData,
      };
    
      axios(config)
        .then(function (response) {
          const AlertHistory = response.data.data.alert_data.alerts.map((element, index) => {
            const createdAtDate = new Date(element.created_at);
            const formattedCreatedAt = createdAtDate.toLocaleString();
            const formattedHostname = Array.isArray(element.hostName) ? element.hostName.join(", ") : element.hostName;
            
            const formattedAlertStateHistory = element.alertStateHistory ? element.alertStateHistory.map(history => ({
              state: history.state,
              updatedAt: new Date(history.updatedAt).toLocaleString(),
              updatedBy: history.updatedBy,
              state_note: history.state_note || 'N/A',
              state_reason: history.state_reason || 'N/A'
            })) : [];
            
            return {
              "id": index + 1,
              "alert_id": element.id,
              "created_at": formattedCreatedAt,
              "alert_name": element.alertName,
              "data": element.alertData.sections ? element.alertData.sections : [{ 'msg': element.alertData }],
              "severity": element.severity,
              "hostname": formattedHostname,
              "alertstate": element.alertState,
              "alert_state_history": formattedAlertStateHistory
            };
          });
    
          setRows(AlertHistory);
          setRowCountState(response.data.data.alert_data.totalItems);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };    

    const [openviewdata, setOpenViewData] = React.useState(false);
    
    const [viewdata, setViewData] = useState({});
  
    function handleOpenViewData(data, data2, data4, stateDetails, stateCloseDetails) {
      let severityLabel = '';
      switch (data4) {
        case 1:
          severityLabel = 'High';
          break;
        case 2:
          severityLabel = 'High';
          break;
        case 3:
          severityLabel = 'Medium';
          break;
        case 4:
          severityLabel = 'Low';
          break;
        case 5:
          severityLabel = 'Info';
          break;
        default:
          severityLabel = 'Unknown';
          break;
      }
    
      setViewData({
        'DateTime': data2,
        'Severity': severityLabel,
        'data':data,
        'stateDetails' : stateDetails,
        'stateCloseDetails' : stateCloseDetails
      });
    
      setOpenViewData(true);
    }

    const handleCloseViewData = () => setOpenViewData(false);
  
    const SetupLink = () => {
      window.open('https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet', '_blank');
    };

    const [severitycount, setSeverityCount] = useState({});
    const [alertsnamecount, setAlertsNameCount] = useState({});
    const [hostsnamecount, setHostsNameCount] = useState({});

    React.useEffect(() => {
      fetchSummary(startDate, endDate, selectedAlertname, selectedHostname, selectedAlertstate);
    }, [startDate, endDate, selectedAlertname, selectedHostname, selectedAlertstate]);    

    const fetchSummary = (startDate = '', endDate = '') => {
      var data = {
        "from_ts": startDate,
        "to_ts": endDate,
        "trend_by": "week",
        "alertname": selectedAlertname,
        "hostname" : selectedHostname,
        "alert_state": [ "open", "acknowledged" ]
      };
      
      // if (selectedAlertstate === "open" || selectedAlertstate === "close" || selectedAlertstate === "acknowledged") {
      //   data.alert_state = selectedAlertstate;
      // }

      var jsonData = JSON.stringify(data);

      var config = {
        method: 'post',
        url: Base_URL + '/alerts/summary',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : jsonData
      };

      axios(config)
      .then(function (response) {
        setSeverityCount(response.data.data.severity.counts);
        setAlertsNameCount(response.data.data.alertname.counts);
        setHostsNameCount(response.data.data.hostname.counts);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const counts = severitycount;
    
    const highcount = (parseInt(counts['1']) || 0) + (parseInt(counts['2']) || 0);

    const mediumcount = parseInt(counts['3']) || 0

    const lowcount = parseInt(counts['4']) || 0

    const infocount = parseInt(counts['5']) || 0

    const navigate = useNavigate();

    const AlertHubSetting = () => {
      navigate('/alerting/hubsettings');
    };

    const getRowId = (row) => row.id;

    const getRowHeight = (params) => {
      return 50;
    };
  return (
    <>
    <SessionExpiredAlert />
    <RealTimeSideNav />
    <Header />
    <div>
      <div className="content-wrapper">
      <br />
      <section className="content">
      <div className="container-fluid">
      <div className="row priority-selection">
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleCriticalSeverityClick}>
        <div className="small-box bg-danger" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
          <h3>
            {highcount} <span style={{ fontSize: '10px' }}>(open & ack)</span>
          </h3>
          <p>High</p>
          </div>
          <div className="icon">
            {selectedSeverity === 'high' ? (
              <>
                <div className="check-icon">
                  <CheckCircleIcon style={{ color: 'white', fontSize: '30px', marginTop: '-3%', marginRight: '-2%' }} />
                </div>
                <i className="ion ion-stats-bars" style={{ marginTop: '6%', fontSize: '60px' }} />
              </>
            ) : (
                <i className="ion ion-stats-bars" />
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleHighSeverityClick}>
        <div className="small-box bg-warning" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3 style={{ color: 'white' }}>{mediumcount} <span style={{ fontSize: '10px' }}>(open & ack)</span></h3>
            <p style={{ color: 'white' }}>Medium</p>
          </div>
          <div className="icon">
            {selectedSeverity === 'medium' ? (
              <>
                <div className="check-icon">
                  <CheckCircleIcon style={{ color: 'white', fontSize: '30px', marginTop: '-3%', marginRight: '-2%' }} />
                </div>
                <i className="ion ion-stats-bars" style={{ marginTop: '6%', fontSize: '60px' }} />
              </>
            ) : (
                <i className="ion ion-stats-bars" />
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer' }} onClick={handleLowSeverityClick}>
        <div className="small-box bg-success" style={{ width: '100%', height: '70%' }}>
          <div className="inner">
            <h3>{lowcount} <span style={{ fontSize: '10px' }}>(open & ack)</span></h3>
            <p>Low</p>
          </div>
          <div className="icon">
            {selectedSeverity === 'low' ? (
              <>
                <div className="check-icon">
                  <CheckCircleIcon style={{ color: 'white', fontSize: '30px', marginTop: '-3%', marginRight: '-2%' }} />
                </div>
                <i className="ion ion-stats-bars" style={{ marginTop: '6%', fontSize: '60px' }} />
              </>
            ) : (
                <i className="ion ion-stats-bars" />
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-6" style={{ cursor: 'pointer', height: '50px' }} onClick={handleInfoSeverityClick}>
        <div className="small-box bg-info" style={{ width: '100%', height: '95px' }}>
          <div className="inner">
            <h3>{infocount} <span style={{ fontSize: '10px' }}>(open & ack)</span></h3>
            <p>Info</p>
          </div>
          <div className="icon">
            {selectedSeverity === 'info' ? (
              <>
                <div className="check-icon">
                  <CheckCircleIcon style={{ color: 'white', fontSize: '30px', marginTop: '-3%', marginRight: '-2%' }} />
                </div>
                <i className="ion ion-stats-bars" style={{ marginTop: '6%', fontSize: '60px' }} />
              </>
            ) : (
                <i className="ion ion-stats-bars" />
            )}
          </div>
        </div>
      </div>
      </div>
      </div>
      </section>
        <Box style={{ height: 'auto', width: '98%', marginLeft: '1%', marginTop: '-2%' }}
          sx={{
          '& .super-app-theme--header': {
            backgroundColor: '#060694',
            color: 'white',
            fontSize: '19px'
            },
          }}
          >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
            <div className='hostname-dropdown'>
            <FormControl style={{ minWidth: '200px' }} variant="outlined">
                <InputLabel id="hostname-label" shrink>
                  Hostname
                </InputLabel>
                <Select
                  labelId="hostname-label"
                  id="hostname-select"
                  value={selectedHostname.length === 0 ? 'All Hosts' : selectedHostname}
                  onChange={handleHostnameChange}
                  label="Hostname"
                >
                  <MenuItem value="All Hosts">All Hosts</MenuItem>
                  {hostnames.map((hostname) => (
                    <MenuItem key={hostname} value={hostname}>
                      {hostname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ minWidth: '200px' }} variant="outlined">
                <InputLabel id="alertname-label" shrink>
                  Alert Names
                </InputLabel>
                <Select
                  labelId="alertname-label"
                  id="alertname-select"
                  value={selectedAlertname.length === 0 ? 'All Alerts' : selectedAlertname}
                  onChange={handleAlertnameChange}
                  label="Alert Names"
                >
                  <MenuItem value="All Alerts">All Alerts</MenuItem>
                  {alertnames.map((alertname) => (
                    <MenuItem key={alertname} value={alertname}>
                      {alertname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ minWidth: '200px' }} variant="outlined">
                <InputLabel id="alert-state-label" shrink>
                  Alert State
                </InputLabel>
                <Select
                  labelId="alert-state-label"
                  id="alert-state-select"
                  value={selectedAlertstate === '' ? 'All States' : selectedAlertstate}
                  onChange={handleAlertstateChange}
                  label="Alert State"
                >
                  <MenuItem value="All States">All States</MenuItem>
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="acknowledged">Acknowledged</MenuItem>
                  <MenuItem value="close">Closed</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <RadioGroup
                row
                aria-label="time-period"
                name="time-period"
                value={selectedTimePeriod}
                onChange={handleTimePeriodChange}
              >
                <FormControlLabel value="last24" control={<Radio />} label="Last 24 Hours" />
                <FormControlLabel value="last7" control={<Radio />} label="Last 7 Days" />
                <FormControlLabel value="all" control={<Radio />} label="All" />
              </RadioGroup>
            </div>
          </div>
          {
            localStorage.getItem('userRole') === "trialuser" ?
            <>
            {showDropdown && (
              <div style={{ position: 'absolute', marginLeft: '70%', backgroundColor: '#f9f9f9', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', padding: '12px', zIndex: '10', width: '140px' }}>
                <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleAlertsClick}><b>Alerts Ruleset</b></div>
                <hr />
                <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleTeamsAlertClick}><b>Teams Alert</b></div>
              </div>
            )}
            </>
            :
            <>
            {showDropdown && (
              <div style={{ position: 'absolute', marginLeft: '70%', backgroundColor: '#f9f9f9', boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', padding: '12px', zIndex: '10', width: '140px' }}>
                <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleAlertsClick}><b>Alerts Ruleset</b></div>
                <hr />
                <div className="dropdownOption" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={handleMonitorsClick}><b>Teams Alert</b></div>
              </div>
            )}
            </>
          }

        <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="server"
        pagination={true}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        />
        
        </Box>
        <Modal open={open} onClose={handleCloseModal}>
              <>
              <Box sx={style}>
                  <div>
                  Alerts Ruleset
                  {
                    localStorage.getItem('userRole') === "trialuser" ?
                    <>
                    <Button size="small" style={{ marginLeft: '63%' }} onClick={handleTeamsAlertClick} variant="contained" color="success">Enable All</Button>&nbsp;&nbsp;&nbsp;
                    <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Disable All</Button>
                    </>
                    :
                    <>
                    <Button size="small" variant="outlined" onClick={() => AlertHubSetting()} style={{ marginLeft: '45%' }} color="primary">Alert Hub Settings</Button>&nbsp;&nbsp;&nbsp;
                    <Button size="small" onClick={() => UpdateAll(true)} variant="contained" color="success">Enable All</Button>&nbsp;&nbsp;&nbsp;
                    <Button size="small" onClick={() => UpdateAll(false)} variant="outlined" color="error">Disable All</Button>
                    </>
                  }
                  <CloseIcon style={{marginLeft: '1%', cursor:'pointer'}} onClick={handleCloseModal}/><hr />
                  </div>
                  <div style={{ height: '375px', width: '100%' }}>
                  <DataGrid
                    rows={monitordata}
                    columns={monitorcolumns}
                    pagination
                    page={modalpage}
                    onPageChange={handlePageChange}
                    components={{
                      Pagination: GridPagination,
                    }}
                  />
                </div>
                </Box>
                </>
        </Modal>
        <Modal
          open={alertinfoopen}
          onClose={handleAlertInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={viewdatastyle}>
          Alert Info <CloseIcon style={{marginLeft: '90%', cursor:'pointer'}} onClick={handleAlertInfoClose}/><hr />
                  <Typography variant="body2" color="text.secondary">
                    
                  {viewalertinfodata && Object.keys(viewalertinfodata).map((key, i) => 
                  {
                    if(key=='data')
                    {
                      return <AlertInfoDataBlock alertInfoData={viewalertinfodata[key]}></AlertInfoDataBlock>
                    }
                     
                  }) 
                  }
                  </Typography>
        </Box>
        </Modal>
        <Modal open={openmonitor} onClose={handleMonitorCloseModal}>
        {webhookurlexists ? 
        <Box sx={webhookstyle}>
        Update Webhook
        <CloseIcon style={{ marginLeft: '85%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          // value={webhookurlexists}
          defaultValue={webhookurlexists}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookUpdate}>
          Update
        </Button>
        {
          webhookstatus == true ? 
          <Button variant="outlined" color="error" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Deactivate
          </Button>
          :
          <Button variant="outlined" color="success" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Activate
          </Button>
        }
        <Button variant="outlined" color="primary" style={{ marginLeft: '45%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box> : 
        <Box sx={webhookstyle}>
        Add Webhook
        <CloseIcon style={{ marginLeft: '87%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          value={webhookValue}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookSubmit}>
          Add
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '60%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box>
        }
        </Modal>
        <Modal
        open={openviewdata}
        onClose={handleCloseViewData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow:'scroll'}}
      >
        <Box sx={viewdatastyle}>
          <div style={{ fontSize: '20px', fontFamily: 'Poppins, sans-serif'}}>Alert Details</div><CloseIcon style={{marginLeft: '95%', marginTop: '-6%', cursor:'pointer'}} onClick={handleCloseViewData}/><hr />
          <Card style={{ border: '1px solid black', marginTop: '-4%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: '0.7', marginRight: '2%' }}>
                <CardContent>
                  <Typography variant="body1" color="text.primary" style={{fontFamily: 'Poppins, sans-serif'}}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'inline-block' }}>
                        <b>DateTime:</b> <Chip label={viewdata['DateTime']} color="primary" />
                      </div>
                      <div style={{ display: 'inline-block', marginLeft: '5%' }}>
                        <b>Severity:</b> <Chip label={viewdata['Severity']} color="primary" />
                      </div>
                    </div>
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary" style={{fontFamily: 'Poppins, sans-serif'}}>
                    {viewdata &&
                      Object.keys(viewdata).map((key, i) => {
                        if (key === 'data') {
                          return Object.keys(viewdata[key]).map((key1, i) => {
                            return <AlertDataBlock alertData={viewdata[key][key1]}></AlertDataBlock>;
                          });
                        }
                      })}
                  </Typography>
                </CardContent>
              </div>
              <Divider orientation="vertical" flexItem style={{ margin: '0 10px', borderRight: '1px solid black' }}  />
              <div style={{ flex: '0.3', marginLeft: '2%' }}>
                <CardContent>
                  <div style={{ display: 'inline-block', marginLeft: '2%'}}>
                    <b style={{ textDecoration: 'underline', fontSize: '15px' }}>Acknowledge Summary</b>
                    <div style={{ marginTop: '8px' }}>
                      <div>
                        {viewdata.stateDetails && viewdata.stateDetails.split('\n')[0] ? (
                          <div>
                            <b>Acknowledged At:</b> <div style={{ color: 'green' }}>{viewdata.stateDetails.split('\n')[0].replace('Acknowledged At: ', '')}</div>
                          </div>
                        ) : (
                          <div>
                            <b>Acknowledged At:</b> N/A
                          </div>
                        )}

                        {viewdata.stateDetails && viewdata.stateDetails.split('\n')[1] ? (
                          <div>
                            <b>Acknowledged By:</b> <div style={{ color: 'green' }}>{viewdata.stateDetails.split('\n')[1].replace('Acknowledged By: ', '')}</div>
                          </div>
                        ) : (
                          <div>
                            <b>Acknowledged By:</b> N/A
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', marginLeft: '2%', marginTop: '5%' }}>
                    <b style={{ textDecoration: 'underline', fontSize: '15px' }}>Close Summary</b>
                    <div style={{ marginTop: '8px' }}>
                      <div>
                        {viewdata.stateCloseDetails && viewdata.stateCloseDetails.split('\n')[0] ? (
                          <div>
                            <b>Closed At:</b> <div style={{ color: 'green' }}>{viewdata.stateCloseDetails.split('\n')[0].replace('Closed At: ', '')}</div>
                          </div>
                        ) : (
                          <div>
                            <b>Closed At:</b> N/A
                          </div>
                        )}

                        {viewdata.stateCloseDetails && viewdata.stateCloseDetails.split('\n')[1] ? (
                          <div>
                            <b>Closed By:</b> <div style={{ color: 'green' }}>{viewdata.stateCloseDetails.split('\n')[1].replace('Closed By: ', '')}</div>
                          </div>
                        ) : (
                          <div>
                            <b>Closed By:</b> N/A
                          </div>
                        )}

                        {viewdata.stateCloseDetails && viewdata.stateCloseDetails.split('\n')[2] ? (
                          <div>
                            <b>Closing Notes:</b> <div style={{ color: 'green' }}>{viewdata.stateCloseDetails.split('\n')[2].replace('State Note: ', '')}</div>
                          </div>
                        ) : (
                          <div>
                            <b>Closing Notes:</b> N/A
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>
            </div>
          </Card>
        </Box>
        </Modal>
        </div>
    </div>   
    <Footer />
    </>
  )
}

export default Alerting