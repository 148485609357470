import React, { useState,useEffect,useRef  } from 'react'
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { Kibana_URL } from '../constants'
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import Header from '../components/Header';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';


const AlertConfig = (props) => {
  const [cookies, removeCookie ] = useCookies(['access_token', 'sample_token','kibana_token']);

  let token = cookies.kibana_token;
  const iframeRef = useRef(null);
  const iframeStyle = {
  
    marginTop:"-100px",
    width: '100%',
    height: '90vh',
  };
 

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
      <div className="content-wrapper">
      <div>
      <iframe
      style={iframeStyle}
      // src={`${Kibana_URL}app/alerting?jwt=${token}#/monitors?embed=true&from=0&search=&size=20&sortDirection=desc&sortField=name&state=all`}
      src={`${Kibana_URL}app/alerting?security_tenant=private&jwt=${token}#/monitors?from=0&search=&size=20&sortDirection=desc&sortField=name&state=all`}
      />
      </div>
        <br />
      </div>
    {/* <div style={{position:'sticky',backgroundColor:'white'}} >
      <h1>Monitor</h1>
      </div> */}
    <Footer />
    </>
  )
}

export default AlertConfig