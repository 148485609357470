import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useCookies } from 'react-cookie';
import { Base_URL, Kibana_URL } from '../constants';
import axios from 'axios';

const Feedback = () => {
  const [cookies] = useCookies(['access_token', 'sample_token', 'kibana_token']);
  let token = cookies.kibana_token;
  const containerStyle = {
    position: 'relative',
  };
  const iframeStyle = {
    width: '100%',
    height: '80vh',
  };
  const knowMoreStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    padding: '10px',
  };

  useEffect(() => {
    dashboardCall('');
  }, []);

  const dashboardCall = async => {
    var data = JSON.stringify({
      "dash_type": "feedback_dashboard",
      "upload_tag": "feedback"
    });

    var config = {
      method: 'post',
      url: Base_URL + '/users/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      
    })
    .catch(function (error) {
      
    });
  }

  return (
    <>
      <SideNav />
      <Header />
      <div className="content-wrapper" style={containerStyle}>
        <div style={knowMoreStyle}>
          <Button
            variant="outlined"
            href="https://wowizer.ai/wiki/wowizer-tpm/extensions-for-real-time/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Know More
          </Button>
        </div>
        <iframe
          src={`${Kibana_URL}app/dashboards?security_tenant=private&jwt=${token}#/view/98f71610-b44f-11ee-a683-616140031616?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Feedback%20Dashboard',viewMode:view)&show-time-filter=true`}
          style={iframeStyle}
        />
      </div>
      <Footer />
    </>
  );
};

export default Feedback;