import React from 'react'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Base_URL } from '../constants';
import axios from 'axios';
import { FcCalendar, FcDocument, FcFeedback, FcLeave, FcViewDetails } from 'react-icons/fc';
import SessionExpiredAlert from '../components/SessionExpiredAlert';

const Subscription = () => {
    const [subscriptionDetails, setSubscriptionDetails] = React.useState({});

    const [email, setEmail] = React.useState('');
    const [displayEmail, setDisplayEmail] = React.useState('');

    React.useEffect(() => {
        fetchData('');
    }, []);

    const fetchData = async => {
        var config = {
            method: 'get',
            url: Base_URL + '/users/me',
            headers: { }
          };
          
          axios(config)
          .then(function (response) {
            const admin_email = response.data.data.super_user.email;
            const email = response.data.data.user.email;
            const subscription = response.data.data.subscription;
            setSubscriptionDetails(subscription);
            setEmail(email);
            setDisplayEmail(admin_email === email ? email : `${admin_email}`)
          })
          .catch(function (error) {
            console.log(error);
          });
    }

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
    <div>
        <div className="content-wrapper">
          <br />
          <div className="card" style={{width: '50%', marginLeft: '25%', marginTop:'-0.5%'}}>
          <div className="card-header border-0">
            <h3 className="card-title">My Subscriptions</h3>
          </div>
          <div className="card-body">
          <div className="d-flex align-items-center border-bottom mb-3">
            <p className="text-success text-xl">
              <FcFeedback />
            </p>
            {
              localStorage.getItem('userRole') === "trialuser" ?
              <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold">
                    <span style={{color: 'black'}}>Email</span> : <span style={{ color: '#060694'}}>{email}</span>
              </span>
              </p>
              :
              <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold">
                {
                  email === displayEmail ? (
                    <>
                    <span style={{color: 'black'}}>Admin</span> : <span style={{ color: '#060694' }}>{displayEmail}</span>
                    </>
                  ) : (
                    <>
                    <span style={{color: 'black'}}>Admin</span> : <span style={{ color: '#060694' }}>{displayEmail}</span>
                    <br />
                    <span style={{color: 'black'}}>Email</span> : <span style={{ color: '#060694'}}>{email}</span>
                    </>
                  )
                }
              </span>
            </p>
            }
          </div>
          <div className="d-flex align-items-center border-bottom mb-3">
            <p className="text-success text-xl">
              <FcViewDetails />
            </p>
            <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold"><span style={{color: 'black'}}>Subscription Name</span> : <span style={{ color: '#060694'}}>{subscriptionDetails.subscriptionName}</span></span>
            </p>
          </div>
          <div className="d-flex align-items-center border-bottom mb-3">
            <p className="text-success text-xl">
              <FcDocument />
            </p>
            <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold"><span style={{color: 'black'}}>Plan Id</span> : <span style={{ color: '#060694'}}>{subscriptionDetails.planId}</span></span>
            </p>
          </div>
          <div className="d-flex align-items-center border-bottom mb-3">
            <p className="text-success text-xl">
              <FcCalendar />
            </p>
            <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold">
              <span style={{color: 'black'}}>Activation Date</span> : <span style={{ color: '#060694'}}>{new Date(subscriptionDetails.activationDate).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  }).split(' ').join('-').replace(',', '')}</span>
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-0">
            <p className="text-success text-xl">
              <FcLeave />
            </p>
            <p style={{marginLeft: '3%'}}>
              <span className="font-weight-bold">
              <span style={{color: 'black'}}>Expiry Date</span> : <span style={{ color: '#060694'}}>{new Date(subscriptionDetails.expiryDate).toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  }).split(' ').join('-').replace(',', '')}</span>
              </span>
            </p>
          </div>
        </div>
        </div>

        </div>
    </div>
    <Footer />
    </>
  )
}

export default Subscription