import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, IconButton } from '@mui/material';
import { AccountCircle, ContactPhone, Email, Lock, Work } from '@mui/icons-material';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.svg';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Base_URL, Register_User } from '../constants';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import randomstring from 'randomstring';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {

  const [fullName, setFullName] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_contact, setUserContact] = useState('');
  const [user_name, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [user_company, setUserCompany] = useState('');
  const [country, setCountry] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [newsletter, setNewsLetter] = useState(true);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [captchaValue, setCaptchaValue] = useState('');

  const generateCaptcha = () => {
    return randomstring.generate({
      length: 6,
      charset: 'alphanumeric',
    });
  };
  
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userCaptcha, setUserCaptcha] = useState('');
  
  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserCaptcha('');
  };  
  
  useEffect(() => {
    const names = fullName.split(' ');
    setUserName(names[0]);
    setLastName(names.length > 1 ? names[names.length - 1] : '');
  }, [fullName]);

  const generateCaptchaImage = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 150;
    canvas.height = 50;
    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '30px Arial';
    context.fillStyle = '#000000';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, canvas.width / 2, canvas.height / 2);
    const dataUrl = canvas.toDataURL('image/png');
    
    return dataUrl;
  };
  
  useEffect(() => {
    const handlePopState = () => {
      handleRefreshCaptcha();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegisterSubmit = (e) => {
    showLoader();
    e.preventDefault();

    // if (userCaptcha.toLowerCase() !== captcha.toLowerCase()) {
    //   hideLoader();
    //   alert('Incorrect captcha');
    //   handleRefreshCaptcha();
    //   return;
    // }

    if (agreeToTerms) {
      const notallowedCountries = ['Iran', 'North Korea', 'Syria', 'Cuba', 'Venezuela', 'Russia', 'Ukraine', 'Belarus'];

    if (notallowedCountries.includes(country)) {
      hideLoader();
      swal({
        title: "Sorry we are not available in your country yet",
        text: "",
        icon: "error",
      });
      return;
    }

    if (!validateEmail(user_email)) {
      hideLoader();
      swal({
        title: "Invalid Email Format",
        text: "Please enter a valid email address.",
        icon: "error",
      });
      return;
    }

    const notallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
    const emailDomain = user_email.split('@')[1];
    if (notallowedDomains.includes(emailDomain)) {
      hideLoader();
      swal({
        title: "Valid Company Email is required for Sign Up",
        // text: "Please provide an email address with a different domain.",
        icon: "error",
      });
      return;
    }

    // if (!user_contact) {
    //   hideLoader();
    //   swal({
    //       title: "Please Enter a Contact Number",
    //       text: "You must provide a contact number to sign up.",
    //       icon: "error",
    //   });
    //   return;
    // }

    var data = JSON.stringify({
      "firstName": user_name,
      "lastName": lastName,
      "email": user_email,
      // "mobile": "+" + user_contact,
      "companyName": user_company,
      // "country" : country,
      "em_subscribed": newsletter
    });
    
    var config = {
      method: 'post',
      url: Base_URL + Register_User,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    
    axios(config)
      .then(function (response) {
        hideLoader();
        swal({
          title: "Thanks for signing up!",
          text: "Your request is being processed. We will contact you once your account is created.",
          icon: "success",
        }).then(function () {
          window.location.href = 'https://wowizer.ai/blog/introducing-wowizer-telemetry-and-performance-monitoring-tpm-for-qlik-sense/'
        });
      })
      .catch(function (error) {
        hideLoader();
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error",
        })
      });
    } else {
      hideLoader();
      alert('Please agree to the Privacy Policy and License Terms & Conditions to sign up.');
    }
  };

  const handlePhoneInputChange = (phone, country) => {
    setUserContact(phone);
    setCountry(country.name);
  };

  const handleContactChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setUserContact(inputValue);
    }
  };

  return (
    <>
      <img className="wave" src={wave1} />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} />
        </div>
        <div className="login-content" style={{ marginTop: '7%' }}>
          <form className='form-class-login' onSubmit={handleRegisterSubmit}>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
              </div>
            </div>
            <br />
            <div style={{ fontSize: '20px', color: '#000080' }}>Take control of Qlik user experience and drive adoption.</div>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <br />
                <TextField
                  id="fullName"
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  InputProps={{
                    startAdornment: <AccountCircle />,
                  }}
                  required
                />
              </Grid>
              {/* <Grid item xs={6}>
                <br />
                <TextField
                  id="contact"
                  label="Contact"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={user_contact}
                  onChange={handleContactChange} // Use the custom event handler
                  InputProps={{
                    startAdornment: <ContactPhone />,
                  }}
                  required
                />
              </Grid> */}
              <Grid item xs={6}>
                <PhoneInput
                  inputProps={{
                    id: 'contact',
                    name: 'contact',
                  }}
                  country={'us'}
                  value={user_contact}
                  onChange={handlePhoneInputChange}
                  inputStyle={{
                    width: '100%',
                    height: '100%',
                    border: '1px solid #ced4da',
                    borderRadius: '4px',
                    // padding: '10px 20px',
                  }}
                  containerStyle={{
                    marginTop: '15%',
                    width: '100%',
                    height: '50%'
                  }}
                  inputClass="form-control"
                  InputProps={{
                    required: true,
                    placeholder: 'Contact Number'
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="Company Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={user_email}
                  onChange={(e) => setUserEmail(e.target.value)}
                  InputProps={{
                    startAdornment: <Email />,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="company"
                  label="Company"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={user_company}
                  onChange={(e) => setUserCompany(e.target.value)}
                  InputProps={{
                    startAdornment: <Work />,
                  }}
                  required
                />
              </Grid>
            </Grid>
            <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By signing up, you agree to the <a href='https://wowizer.ai/privacy-policy/' target="_blank">Privacy Policy</a> and <a href='https://wowizer.ai/eula/' target="_blank">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                <ReCAPTCHA
                  sitekey="6LcaWMopAAAAADmFpByzS8hyttrY1fO0UfHjIaWq"
                  onChange={(value) => setCaptchaValue(value)}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={newsletter}
                    onChange={(e) => setNewsLetter(!newsletter)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    Subscribe to WoWizer TPM newsletter
                  </span>
                </div>
              </div>
            </div> */}
            {/* <Grid item xs={6}>
              <TextField
                id="captcha"
                label="Enter Captcha"
                variant="outlined"
                fullWidth
                margin="normal"
                value={userCaptcha}
                onChange={(e) => setUserCaptcha(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <img
                        src={generateCaptchaImage(captcha)}
                        alt="captcha"
                        style={{ height: '40px' }}
                      />
                      <IconButton onClick={handleRefreshCaptcha}>
                        <RefreshIcon />
                      </IconButton>
                    </React.Fragment>
                  ),
                }}
                sx={{ width: 'calc(60% - 16px)' }}
                required
              />
            </Grid> */}
            <div className="row">
              <div className="col">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: '50px' }}
                  type='submit'
                  disabled={!captchaValue}
                >
                  Register
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Link to="/" style={{ textDecoration: 'none', fontWeight: "bolder" }}>
                  Already Registered? Login
                </Link>
              </div>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </>
  );
};

export default Register;