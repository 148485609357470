import { FcCustomerSupport } from "react-icons/fc";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const handleSupport = () => {
    window.open('/help', '_blank');
  };

  return (
    <div>
      <footer className="main-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <strong>Copyright © {currentYear} <a href="">WOWizer</a>. All rights reserved.</strong>
        <div className="chat-icon-container">
          <FcCustomerSupport style={{ fontSize: '30px', cursor: 'pointer' }} onClick={handleSupport}/>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
