import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import img from '../../src/images/New Wowizer Logo 1.svg'
import { Base_URL, Logout_User } from "../constants";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { TableChart } from "@mui/icons-material";
import SessionExpiredAlert from "./SessionExpiredAlert";
import Signout from "./Signout";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DescriptionIcon from '@mui/icons-material/Description';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, ButtonGroup } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

const RealTimeSideNav = () => {

  const [cookies, setCookie, removeCookie] = useCookies();
  
  const handleLogout = () => {
    var config = {
      method: 'get',
      url: Base_URL + Logout_User,
      headers: {}
  };

  axios(config)
      .then(function (response) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
      })
      .catch(function (error) {
        var config = {
        method: 'get',
        url: Base_URL + '/auth/refresh',
        headers: { }
        };

        axios(config)
        .then(function (response) {
          var config = {
            method: 'get',
            url: Base_URL + Logout_User,
            headers: {}
          };
          axios(config)
          .then(function (response) {
            localStorage.clear();
            window.location.href = '/';
          })
        })
        .catch(function (error) {
        console.log(error);
        });
      });
  };

  const location = useLocation();

  const [subscriptionDetails, setSubscriptionDetails] = React.useState({});

  const [email, setEmail] = React.useState('');
  const [userRole, setUserRole] = React.useState('');
  const [displayEmail, setDisplayEmail] = React.useState('');

  React.useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + '/users/me',
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          const admin_email = response.data.data.super_user.email;
          const email = response.data.data.user.email;
          const subscription = response.data.data.subscription;
          localStorage.setItem('userRole', response.data.data.user.role);
          localStorage.setItem('archivalretention', response.data.data.subscription.archiveRetentionDays);
          localStorage.setItem('liveretention', response.data.data.subscription.liveRetentionDays);
          setSubscriptionDetails(subscription);
          setUserRole(response.data.data.user.role)
          setEmail(email);
          setDisplayEmail(admin_email === email ? email : `${admin_email}`)
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const [isHistoricalOpen, setIsHistoricalOpen] = React.useState(false);

  const toggleHistorical = () => {
    setIsHistoricalOpen(!isHistoricalOpen);
  };

  const [isRealtimeOpen, setIsRealtimeOpen] = React.useState(false);

  const toggleRealtime = () => {
    setIsRealtimeOpen(!isRealtimeOpen);
  };

  const [isAdminPanelOpen, setIsAdminPanelOpen] = React.useState(false);

  const toggleAdminPanel = () => {
    setIsAdminPanelOpen(!isAdminPanelOpen);
  };

  const Realtime = () => {
    localStorage.setItem("logtype", "realtime");
  };

  const [selectedMenu, setSelectedMenu] = useState('Real Time');

  const navigate = useNavigate();

  const handleMenuChange = (menu) => {
    setSelectedMenu(menu);
    if (menu === 'Real Time') {
      navigate('/analyzelogs/realtimelogs/QV00/3');
      localStorage.setItem('currentNav', "Real Time");
    } else if (menu === 'Historical') {
      navigate('/selectuploadtag');
      localStorage.setItem('currentNav', "Historical");
      window.sessionStorage.setItem("lastUrl:/kibana:dashboard", "")
    }
  };

  const adminSubMenuPaths = ['/realtimelogs', '/manageusers', '/archival', '/alertingconfig', '/autoremediation'];

  useEffect(() => {
    if (adminSubMenuPaths.includes(location.pathname)) {
      setIsAdminPanelOpen(true);
    } else {
      setIsAdminPanelOpen(false);
    }
  }, [location.pathname]);
  
  return (
    <>
    <SessionExpiredAlert />
    <div>
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/analyzelogs/realtimelogs/QV00/3" className="brand-link">
        <img src={img} alt="AdminLTE Logo" />
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <ButtonGroup variant="contained">
            <Button 
              onClick={() => handleMenuChange('Real Time')} 
              color={selectedMenu === 'Real Time' ? 'success' : 'inherit'}
              sx={{
                borderRadius: '50px',
                backgroundColor: selectedMenu !== 'Real Time' ? 'gray' : '',
                color: selectedMenu !== 'Real Time' ? 'white' : '',
                '&:hover': {
                  backgroundColor: selectedMenu !== 'Real Time' ? '#5f5f5f' : '',
                }
              }}
            >
              Real Time
            </Button>
            <Button 
              onClick={() => handleMenuChange('Historical')} 
              color={selectedMenu === 'Historical' ? 'success' : 'inherit'}
              sx={{
                borderRadius: '50px',
                backgroundColor: selectedMenu !== 'Historical' ? 'gray' : '',
                color: selectedMenu !== 'Historical' ? 'white' : '',
                '&:hover': {
                  backgroundColor: selectedMenu !== 'Historical' ? '#5f5f5f' : '',
                }
              }}
            >
              Historical
            </Button>
          </ButtonGroup>
            <br />
            <li
              className={`first-step nav-item ${location.pathname === '/analyzelogs/realtimelogs/QV00/3' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
              <Link to='/analyzelogs/realtimelogs/QV00/3' className="nav-link">
                <TroubleshootIcon />
                <p>
                  Real Time Monitoring
                </p>
              </Link>
            </li>
            <li className={`second-step nav-item ${location.pathname === '/aitroubleshoot/realtimelogs' ? 'active' : ''}`}
            style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
            <Link to='/aitroubleshoot/realtimelogs' className="nav-link">
              <VisibilityIcon />
              <p>
                &nbsp;AI Troubleshoot
              </p>
            </Link>
            </li>
            <li className={`thrid-step nav-item ${location.pathname === '/alerting' ? 'active' : ''}`}
            style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
              <Link to='/alerting' className="nav-link">
                <AddAlertIcon />
                <p>
                  Alerts
                </p>
              </Link>
            </li>
            <li className={`fourth-step nav-item ${location.pathname === '/generatedreports' ? 'active' : ''}`}
            style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
              <Link to='/generatedreports' className="nav-link">
                <DescriptionIcon />
                <p>
                  Daily Reports
                </p>
              </Link>
            </li>
            <li className={`fifth-step nav-item ${location.pathname === '/feedback' ? 'active' : ''}`}
            style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
              <Link to='/feedback' className="nav-link">
                <RateReviewIcon />
                <p>
                  User Feedback
                </p>
              </Link>
            </li>
            <li className={`parent-node sixth-step nav-item ${isAdminPanelOpen ? 'menu-open' : ''}`}
            style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
            >
            <Link to='#' className="nav-link" onClick={toggleAdminPanel}>
              <ManageAccountsIcon />
              <p>
                Admin
                <i className={`right fas fa-angle-right ${isAdminPanelOpen ? 'rotate' : ''}`}></i>
              </p>
            </Link>
            <ul className={`nav nav-treeview ${isAdminPanelOpen ? 'd-block' : 'd-none'}`}>
              <li className={`nav-item ${location.pathname === '/realtimelogs' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
              >
                <Link to='/realtimelogs' className="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>-&nbsp;&nbsp;Realtime Status</p>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/manageusers' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
              >
                <Link to='/manageusers' className="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>-&nbsp;&nbsp;User Management</p>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/archival' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
              >
                <Link to='/archival' className="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>-&nbsp;&nbsp;Data Management</p>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/alertingconfig' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
              >
                <Link to='/alertingconfig' className="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>-&nbsp;&nbsp;Alerts Configuration</p>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === '/autoremediation' ? 'active' : ''}`}
              style={{ borderRadius: '8px', padding: '0px', overflow: 'hidden' }}
              >
                <Link to='/autoremediation' className="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>-&nbsp;&nbsp;Smart Fix (BETA)</p>
                </Link>
              </li>
            </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
  </>
  )
}

export default RealTimeSideNav