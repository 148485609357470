import React from 'react';
import Footer from '../components/Footer';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import SideNav from '../components/SideNav';
import Header from '../components/Header';
import { Button } from '@mui/material';

const SelectedDashboard = ({ dashboardLink }) => {
  const iframeStyle = {
    width: '100%',
    height: 'calc(100vh - 100px)',
    border: 'none',
  };

  const containerStyle = {
    position: 'relative',
  };

  const knowMoreStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    padding: '10px',
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <SessionExpiredAlert />
      <SideNav />
      <Header />
      <div className="content-wrapper" style={containerStyle}>
        <div style={knowMoreStyle}>
          <Button
            variant="outlined"
            onClick={handleRefresh}
          >
            Back
          </Button>
        </div>
        <iframe src={dashboardLink} style={iframeStyle} title="Selected Dashboard"></iframe>
      </div>
      <Footer />
    </>
  );
};

export default SelectedDashboard;