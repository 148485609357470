import React, { useEffect, useState } from 'react'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Base_URL } from '../constants';
import { useNavigate } from "react-router-dom";
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { FcInfo } from 'react-icons/fc';
import swal from 'sweetalert';

const RealTimeLogs = () => {

  const [planId, setPlanId] = React.useState('');

  React.useEffect(() => {
    fetchData('');
}, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + '/users/me',
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          const planId = response.data.data.subscription.planId
          setPlanId(planId)
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const [statusData, setStatusData] = useState([]);
  const [ingestionStatus, setIngestion] = useState([]);
  const [lastSync, setLastSyc] = useState([]);
  
  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await axios.get(Base_URL + '/users/validate_tenant/');
      setStatusData(response.data.tenant_data.server_details);
      setIngestion(response.data.tenant_data.ingestion_status);
      const dateObject = new Date(response.data.event_ts);
      const formattedDate = dateObject.toLocaleString();
      setLastSyc(formattedDate);
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const handleManageRestore = () => {
    navigate('/archival');
  }

  const handleRedirect = () => {
    sessionStorage.clear();
    navigate('/analyzelogs/realtimelogs/QV00/3');

    var data = JSON.stringify({
      "dash_type": "realtime_dashboard",
      "upload_tag": "realtimelogs"
    });

    var config = {
      method: 'post',
      url: Base_URL + '/users/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      
    })
    .catch(function (error) {
      
    });
  };

  const handleAnomalyRedirect = () => {
    navigate('/clusterdata/realtimelogs');
  };

  const tenant_name = localStorage.getItem('tenant_name');

  const handleRefreshClick = () => {
    fetchStatus();
  };

  const handleInfoClick = () => {
    const infoUrl = 'https://wowizer.ai/wiki/wowizer-tpm/troubleshoot/';
    window.open(infoUrl, '_blank');
  };

  const handleTrialUserClick = () => {
    swal({
      icon: 'info',
      title: 'WoWizer TPM Test Drive does not have the functionality!',
      text: "Kindly proceed with the sign up for 7 day FREE TRIAL.",
      buttons: {
        signUp: {
          text: "Complete Sign-up",
          value: "signUp",
          className: "black-text-button",
        },
        cancel: "Cancel",
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      dangerMode: true,
    })
      .then((value) => {
        if (value === "signUp") {
          const signUpPageUrl = "/register";
          window.open(signUpPageUrl, '_blank');
        }
      });
  };

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
    <div>
        <div className="content-wrapper">
          <br />
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-4" style={{ marginLeft: '10%' }}>
                  <div className="card card-primary">
                  <br /><br />
                    <div className="card-body">
                      <div className="card card-primary card-outline" style={{ alignItems: 'center' }}>
                        <div className="card-body">
                          <p className="card-text">
                            Experience real-time Qlik Sense integration for instant log analysis 
                          </p>
                          <a href="https://wowizer.ai/wiki/wowizer-tpm/realtime-connection/" className="card-link" 
                          target="_blank"
                          >Instructions</a>
                        </div>
                      </div>
                      <div>
                          <br />
                          <div>
                            {planId === 'Standard-Static' ? 
                              <>
                              <div>
                              <div>
                                <button type="button" style={{backgroundColor: '#060694', marginLeft: '23%'}} className="btn" disabled title="Your subscription does not allow this feature">
                                  <span style={{ color: 'white' }}>Realtime Dashboard</span>
                                </button>
                              </div>
                              </div>
                              <br /><br />
                              </>
                              : 
                              <>
                              {
                                localStorage.getItem('userRole') === "trialuser" ?
                                <div style={{ fontWeight: 'bold' }}>
                                Unique Stream ID : 
                                <span style={{ color: '#000080', fontSize: '20px'}}>
                                  &nbsp;N/a
                                </span>
                                </div>
                                :
                                <div style={{ fontWeight: 'bold'}}>
                                Unique Stream ID<br /> 
                                <span style={{ color: '#000080', fontSize: '20px'}}>
                                  {tenant_name}
                                </span>
                                </div>
                              }
                              <br />
                              <div>
                              </div>
                              <div>
                              {
                                localStorage.getItem('userRole') === "trialuser" ?
                                <div>
                                <button type="button" style={{backgroundColor: '#060694' }} className="btn" onClick={handleTrialUserClick}>
                                  <span style={{ color: 'white' }}>Realtime Dashboard</span>
                                </button>
                                </div>
                                :
                                <div style={{ position: 'relative' }}>
                                <button type="button" style={{backgroundColor: '#060694'}} className="btn" onClick={handleRedirect}>
                                  <span style={{ color: 'white' }}>Realtime Dashboard</span>
                                </button>
                                </div>
                              }
                              </div>
                              </>
                            }
                          </div>
                          </div>
                          <div style={{ fontWeight: 'bold', marginLeft: '15%', marginTop: '15%'}}>
                            {
                              localStorage.getItem('userRole') === "trialuser" ?
                              <>
                              </>
                              :
                              <>
                              <div style={{ position: 'relative', marginLeft: '10%' }}></div>
                              </>
                            }
                          </div>
                    </div>
                  </div>
                </div>

              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                  <h3 className="card-title">
                    Ingestion Status: {ingestionStatus.toString().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </h3>
                  </div>
                </div>

                <div className="card card-success">
                  <div className="card-header">
                    <h3 className="card-title">Connected Hosts ({statusData.length})</h3>
                    <br />
                    <h3 className="card-title"><div style={{ fontSize: '15px' }}>Last Sync : {lastSync}</div></h3>
                    <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="card-refresh" data-source="widgets.html" data-source-selector="#card-refresh-content"
                      onClick={handleRefreshClick}
                    >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    </div>
                  </div>
                  <div className="card-body" style={{ maxHeight: '297px', overflowY: 'auto' }}>
                    {statusData.map((node, index) => (
                      <div key={index}>
                        <h5><b><div style={{ color : '#007bff' }}>{node.hostname}</div></b></h5>
                        
                        <label>
                          QIX Performance Logging:&nbsp;
                          {node.details.qix_performance_service === 'running' ? (
                            <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          ) : (
                            <>
                            <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-toggle="tooltip"
                              title="Info"
                              onClick={handleInfoClick}
                            >
                              <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                            </button>
                            </>
                          )}
                        </label>
                        <br />
                        <label>
                          Health Data Service:&nbsp;
                          {node.details.healthdata_service === 'running' ? (
                            <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          ) : (
                            <>
                            <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-toggle="tooltip"
                              title="Info"
                              onClick={handleInfoClick}
                            >
                              <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                            </button>
                            </>
                          )}
                        </label>
                        
                        {node.details.qlix_services.map((service, serviceIndex) => (
                          <div key={serviceIndex}>
                            <label>{service.log_source}:&nbsp;</label>
                            {service.status === 'running' ? (
                              <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : (
                              <>
                              <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                              <button
                              type="button"
                              className="btn btn-tool"
                              data-toggle="tooltip"
                              title="Info"
                              onClick={handleInfoClick}
                              >
                              <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                              </button>
                              </>
                            )}
                            <br />
                          </div>
                        ))}
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              </div>
          </div>

          {/* <div className="col-md-3">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Connected Hosts ({statusData.length})</h3>
            </div>
            <div className="card-body" style={{ maxHeight: '210px', overflowY: 'auto' }}>
              {statusData.map((node, index) => (
                <div key={index}>
                  <h5><b>{node.hostname}</b></h5>
                  <p>Status: {node.status}</p>
                  <p>QIX Performance Service: {node.details.qix_performance_service}</p>
                  <p>Health Data Service: {node.details.healthdata_service}</p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Other Services ({Object.keys(otherServices).length})</h3>
            </div>
            <div className="card-body" style={{ maxHeight: '100px', overflowY: 'auto' }}>
              {Object.entries(otherServices).map(([key, value]) => (
                <div>
                  <h5><b>{formatKey(key)}</b></h5>
                  <p>{value}</p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
          </div> */}
          {/* <div className="card" style={{width: '51%', marginLeft: '20%', marginTop:'3%', alignItems: 'center' }}>
            <div className="card-header border-0">
            </div>
            <div className="card-body">
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card card-primary card-outline" style={{width : '510px', height: '100px', marginLeft: '2%'}}>
                        <div className="card-body">
                          <p className="card-text">
                            Experience real-time Qlik Sense integration for instant log analysis 
                          </p>
                          <a href="#" className="card-link" target="_blank">Instructions</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              <br />
              <div>
                {planId === 'Standard-Static' ? 
                  <>
                  <div>
                  <div>
                    <button type="button" style={{backgroundColor: '#060694', marginLeft: '33%'}} className="btn" disabled title="Your subscription does not allow this feature">
                      <span style={{ color: 'white' }}>Realtime Dashboard</span>
                    </button>
                  </div>
                  </div>
                  <br /><br />
                  </>
                  : 
                  <>
                  {
                    localStorage.getItem('userRole') === "trialuser" ?
                    <div style={{ fontWeight: 'bold', marginLeft: '33%' }}>
                    Unique Stream ID : 
                    <span style={{ color: '#000080', fontSize: '20px'}}>
                      &nbsp;N/a
                    </span>
                    </div>
                    :
                    <div style={{ fontWeight: 'bold', marginLeft: '23%' }}>
                    Unique Stream ID : 
                    <span style={{ color: '#000080', fontSize: '20px'}}>
                      {tenant_name}
                    </span>
                    </div>
                  }
                  <br />
                  <div>
                  </div>
                  <div>
                    <button type="button" style={{backgroundColor: '#060694', marginLeft: '33%'}} className="btn" onClick={handleRedirect}>
                      <span style={{ color: 'white' }}>Realtime Dashboard</span>
                    </button>
                  </div>
                  </>
                }
              </div>
              </div>
              <div style={{ fontWeight: 'bold', marginLeft: '2%', marginTop: '15%'}}>
                {
                  localStorage.getItem('userRole') === "trialuser" ?
                  "*Note: WoWizer TPM Test Drive does not have the functionality to upload data. Kindly proceed with the complete sign-up to unlock all features."
                  :
                  <>
                  *Note: Total data ingestion limit upto 1 GB. Reach us if you need custom<br />data limit.
                  </>
                }
              </div>
            </div>
          </div> */}
        </div>
    </div>
    <Footer />
    </>
  )
}

export default RealTimeLogs