import React, { useState } from 'react'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Base_URL } from '../constants'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';

const ManageUsers = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [data, setData] = React.useState({});

    const columns = [
      { field: 'name', headerClassName: 'super-app-theme--header', headerName: 'Full Name', flex: 0.4, cellClassName: (params) => {
        return 'word-wrap';
      } },
      { field: 'email', headerClassName: 'super-app-theme--header', headerName: 'Email', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      } },
      { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Status', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      } },
      { 
        field: 'actions', 
        headerName: 'Actions',
        headerClassName: 'super-app-theme--header',
        flex: 0.5,
        sortable: false,
        renderCell: (params) => {
          const rowIndex = params.row;
          const handleUpdate = () => {
            swal({
              title: "Are you sure to update the setting?",
              text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                var data = JSON.stringify({
                  "userID": rowIndex.id,
                  "account_status": rowIndex.status === 'active' ? 'deactive' : 'active'
                });
                
                var config = {
                  method: 'post',
                  url: Base_URL + '/users/muser/',
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
    
                axios(config)
                .then(function (response) {
                  
                })
                .catch(function (error) {
                  console.log(error);
                });
                swal("Setting Updated Successfully!", {
                  icon: "success",
                }).then(() => {
                  window.location.reload();
                });
              } else {
                swal("Settings not changed!");
              }
            });
          };
          const handleDelete = () => {
            swal({
              title: "Are you sure to delete the user?",
              text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                var data = JSON.stringify({
                  "userID": rowIndex.id
                });
    
                var config = {
                  method: 'delete',
                  url: Base_URL + '/users/muser/',
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
    
                axios(config)
                .then(function (response) {
                  
                })
                .catch(function (error) {
                  console.log(error);
                });
                swal("User Deleted Successfully!", {
                  icon: "success",
                }).then(() => {
                  window.location.reload();
                });
              } else {
                swal("User not deleted!");
              }
            });
          };
    
          return (
            <div>
              {
                localStorage.getItem('userRole') === "trialuser" ?
                <Button 
                  variant="outlined"
                  style={{ width: '120px' }}
                  onClick={handleTrialUserClick}
                >
                  {rowIndex.status === 'active' ? 'Deactivate' : 'Activate'}
                </Button>
                :
                <Button 
                  variant="outlined"
                  style={{ width: '120px' }}
                  onClick={handleUpdate}
                >
                  {rowIndex.status === 'active' ? 'Deactivate' : 'Activate'}
                </Button>
              }
              {
                localStorage.getItem('userRole') === "trialuser" ?
                <Button variant="outlined" color="error" onClick={handleTrialUserClick} style={{ marginLeft: '5%'}}>Delete</Button>
                :
                <Button variant="outlined" color="error" onClick={handleDelete} style={{ marginLeft: '5%'}}>Delete</Button>
              }
            </div>
          );
        }
      }
    ];

    React.useEffect(() => {
      if (localStorage.getItem('userRole') === "trialuser") {
          setData([
              { id: 1, name: 'John Doe', email: 'john.doe@example.com', status: 'active' }
          ]);
      } else {
          fetchData();
      }
    }, []);
  

    const fetchData = async () => {
      showLoader();
      const config = {
          method: 'get',
          url: `${Base_URL}/users/muser/`,
          headers: {}
      };
  
      axios(config)
          .then(response => {
              hideLoader();
              const UsersList = response.data.data.resData.map(element => ({
                  id: element.userId,
                  name: `${element.firstName} ${element.lastName}`,
                  status: element.status,
                  email: element.email
              }));
              setData(UsersList);
          })
          .catch(error => {
              hideLoader();
              console.log(error);
          });
    };

    const CustomFooter = () => null;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    };

    const handleSubmit = () => {
      showLoader();
      setEmailError(false);
      setFirstNameError(false);
      setLastNameError(false);

      if (firstName.trim() === '') {
        setFirstNameError(true);
      }
  
      if (lastName.trim() === '') {
        setLastNameError(true);
      }

      if (!validateEmail(email)) {
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }

      if (firstName.trim() !== '' && lastName.trim() !== '' && validateEmail(email)) {
        const data = JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
        });
  
        const config = {
          method: 'post',
          url: Base_URL + '/users/register/ro',
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        };
  
        axios(config)
          .then(function (response) {
            hideLoader();
            swal("User Invited!", {
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
            handleClose();
          })
          .catch(function (error) {
            hideLoader();
            swal(error.response.data.message, {
              icon: "error",
            }).then(() => {
              window.location.reload();
            });
            handleClose();
          });
      }
    };

    const handleTrialUserClick = () => {
      swal({
        icon: 'info',
        title: 'WoWizer TPM Test Drive does not have the functionality!',
        text: "Kindly proceed with the complete sign-up to unlock all features.",
        buttons: {
          signUp: {
            text: "Complete Sign-up",
            value: "signUp",
            className: "black-text-button",
          },
          cancel: "Cancel",
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        dangerMode: true,
      })
        .then((value) => {
          if (value === "signUp") {
            const signUpPageUrl = "/register";
            window.open(signUpPageUrl, '_blank');
          }
        });
    };

  return (
    <>
    <SessionExpiredAlert />
    <SideNav />
    <Header />
    <div>
        <div className="content-wrapper">
        <br />
        {
          localStorage.getItem('userRole') === "trialuser" ?
          <Button className="invite-btn" variant='outlined' style={{ marginLeft: '1%', }} onClick={handleTrialUserClick}>Invite User</Button>
          :
          <Button className="invite-btn" variant='outlined' style={{ marginLeft: '1%', }} onClick={handleOpen}>Invite User</Button>
        }
        <Box style={{ height: 400, width: '98%', marginLeft: '1%', marginTop: '1%' }}
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontSize: '19px'
              },
            }}
            >
                <DataGrid
                rows={data}
                columns={columns}
                components={{
                    Footer: CustomFooter,
                }}
                />
            </Box>
        </div>
    </div>
    <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <br />
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            error={firstNameError}
            helperText={firstNameError ? 'First name is required' : ''}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            error={lastNameError}
            helperText={lastNameError ? 'Last name is required' : ''}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            error={emailError}
            helperText={emailError ? 'Invalid email address' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button color="success" onClick={handleSubmit} variant="contained">
            Invite User
          </Button>
        </DialogActions>
        <br />
      </Dialog>
      {loader}
    <Footer />
    </>
  )
}

export default ManageUsers